import axios, { AxiosResponse } from "axios";
import secureLocalStorage from "react-secure-storage";

type GeApi = {
  get: (params?: object) => Promise<any>;
  put: (params?: object) => Promise<any>;
  [key: string]: (params?: object) => Promise<any>;
};

export default function geApi(endpoint: string, usePrefix = true): GeApi {
  const USE_MOCK_AUTH = false;
  // USE_MOCK_AUTH = true;
  const api = import.meta.env.VITE_APP_GE_API_URL;
  const url = usePrefix ? `${api}/ge/${endpoint}` : `${api}/${endpoint}`;
  const jwt = getGeJwt(USE_MOCK_AUTH);
  const headers = getGeHeaders(jwt);

  return {
    get: async (params?: object) => {
      return await axios.get(url, { params, headers });
    },
    post: async (params) => {
      try {
        return await axios.post(url, params, { headers });
      } catch (error) {
        console.log("error", error);
        console.log(url, params, headers);
      }
    },
    put: async (params?: object) => {
      return await axios.put(url, params, { headers });
    },
    delete: async (params) => {
      return await axios.delete(url, { params, headers });
    },
  };
}

export const getGeJwt = (USE_MOCK_AUTH?: boolean) => {
  const isDev = process.env.NODE_ENV === "development";
  if (USE_MOCK_AUTH === true && isDev) {
    // const name = 'Bob'; // One streaks
    // const name = 'Carol'; // 1 month streak
    // const name = 'David'; // 1 week, 3 month streak
    // const name = 'Eve'; // No events
    // const name = 'Frank'; // no streaks
    const name = "Alice"; // Streaks & Achievements
    const userByName = (u: MockUser) => u.name === name;
    const tester = MOCK_TEST_USERS.find(userByName);
    return tester ? tester.jwt : "";
  }

  const tokenData = secureLocalStorage.getItem("token") as string;
  return tokenData ? JSON.parse(tokenData).access : null;
};

export const getGeHeaders = (jwt = getGeJwt()) => {
  const language = navigator.language;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    "x-ge-brand": "ba",
    "x-ge-client": "ba_react_1_2_3",
    "x-ge-language": language,
    "x-ge-timezone": timezone,
    "x-ge-jwt": jwt,
  };
};

export async function deleteConnectionById(id: number) {
  const api = geApi(`data/connections/${id}`);
  await api.delete();
}

export async function syncConnectionById(id: number) {
  const api = geApi(`data/connections/${id}/sync`);
  await api.post();
}

export type MockUser = typeof MOCK_TEST_USERS[0];
export const MOCK_TEST_USERS = [
  {
    name: "Alice",
    brand_user_ident: "test-alice",
    timezone: "America/Chicago",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWFsaWNlIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9.v3bFber-LW76QbfekIlitTXEbx25tSmSFZXJ2LaTyBQ",
  },
  {
    name: "Bob",
    brand_user_ident: "test-bob",
    timezone: "America/Los_Angeles",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWJvYiIsImV4cCI6MjUyNDYyOTYwMCwiaWF0IjoxNzIwNDY1Nzk1LCJpc190ZXN0Ijp0cnVlfQ.09EASS1khaOI9u6XgHVZhIHB5LkpnxYX_HORpdz1ryA",
  },
  {
    name: "Carol",
    brand_user_ident: "test-carol",
    timezone: "America/New_York",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWNhcm9sIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9._izlAkjGX4R5zflxxd0m8y12bIM0PeLw71yNW9bRjCY",
  },
  {
    name: "David",
    brand_user_ident: "test-david",
    timezone: "America/Denver",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWRhdmlkIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9.HFHEwGBziB8Qbn0dihF74TH9bjXK78G0qo-dq_rQpvM",
  },
  {
    name: "Eve",
    brand_user_ident: "test-eve",
    timezone: "Pacific/Honolulu",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWV2ZSIsImV4cCI6MjUyNDYyOTYwMCwiaWF0IjoxNzIwNDY1Nzk1LCJpc190ZXN0Ijp0cnVlfQ.0EFJUkXojuAMLOAHJd_LaMnO9KackPt1782wWIiM2G0",
  },
  {
    name: "Frank",
    brand_user_ident: "test-frank",
    timezone: "America/Chicago",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWZyYW5rIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9.ssF40sLGW7_Qt9K3iIdV06JXiVdFjU904-raEsZX1QE",
  },
];
