import { useCallback, useEffect, useState } from "react";
import geApi, { getGeHeaders, getGeJwt } from "@utils/geApi";
import {
  ApiResponse,
  BrandGoalType,
  EndpointResponse,
  Steps,
  Streak,
  UserMe,
  UserStats,
} from "ge_api";
import { useQuery } from "@tanstack/react-query";
import { Connection } from "ge_api";
import { GOAL_TYPE_IDENTIFIERS } from "src/constants";

export const useGeJwt = () => {
  const jwt = getGeJwt();
  const headers = getGeHeaders(jwt);

  return {
    jwt,
    headers,
  };
};

export function useGeApi<T>(
  endpoint: string,
  params?: object,
  method = "get",
  usePrefix = true
): ApiResponse<T> {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [{ endpoint, method, params }],
    queryFn: async () => {
      const api = geApi(endpoint, usePrefix);
      const response = await api[method](params);
      return response.data as T;
    },
    refetchOnWindowFocus: true,
    staleTime: 5000,
  });
  return { data, isLoading, error, refetch };
}

export function useGeEndpoint<T>(endpoint: string, params?: object) {
  const { data, isLoading, error, refetch } = useGeApi<EndpointResponse<T>>(endpoint, params);

  const paginationSchema = {
    page: 0,
    per_page: 0,
    results: [],
    total: 0,
    total_pages: 0,
  };

  const { page, per_page, results = [], total, total_pages } = data || paginationSchema;

  return {
    data: results,
    refetch,
    error,
    isLoading,
    page,
    per_page,
    total,
    total_pages,
  };
}

export function useGeEndpointInfinite<T>(endpoint: string, initialParams?: object) {
  const [params] = useState(initialParams);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState<T[]>([]);

  const { data, isLoading, error, total, total_pages, page, per_page } = useGeEndpoint<T>(
    endpoint,
    {
      ...params,
      page: currentPage,
    }
  );

  useEffect(() => {
    if (data.length > 0) {
      setAccumulatedData((prevData) => [...prevData, ...data]);
    }
  }, [data]);

  const loadMore = useCallback(() => {
    const hasMorePages = currentPage < total_pages;
    if (hasMorePages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, total_pages]);

  const hasMore = currentPage < total_pages;

  return {
    data: accumulatedData,
    isLoading,
    error,
    total,
    total_pages,
    page,
    per_page,
    loadMore,
    hasMore,
  };
}

export const useGeStreaks = (params?: object) => {
  const api = useGeApi<UserMe>("users/me", params);
  const streaks = api.data?.progress?.goals?.streak || [];
  const findStreakById = (id: number): Streak =>
    streaks?.find((streak: Streak) => streak.goal_id === id) as Streak;

  // TODO: move hardcode ids out of codebase
  // get from brand config instead
  const { featured_streak_ids } = {
    featured_streak_ids: {
      day: 1,
      week: 2,
      month: 3,
    },
  };

  const featured = Object.entries(featured_streak_ids);

  return {
    ...api,
    streaks,
    findStreakById,
    featured,
    featured_streak_ids,
  };
};

export const useGePrimerStatus = () => {
  const me = useGeApi<UserMe>("users/me");
  const { is_seen_primer, is_seen_points_primer } = me.data?.settings || {
    is_seen_primer: true,
    is_seen_points_primer: true,
  };

  const showPrimer = !is_seen_primer;
  const showPoints = !showPrimer && !is_seen_points_primer;
  const enablePoints = showPrimer && !is_seen_points_primer;

  return {
    showPoints,
    showPrimer,
    enablePoints,
  };
};

export const useGePoints = (params?: object) => {
  const me = useGeApi<UserMe>("users/me", params);
  const points = me.data?.stats?.BrandStat_1 || ({} as UserStats);
  return {
    ...me,
    points,
  };
};

type I = Connection["service_ident"];
export const useGeDataConnections = () => {
  const api = useGeEndpoint<Connection>("data/connections", {});
  const { findConnection, byIdent, postServiceReturn } = useMethods();

  return {
    ...api,
    findConnection,
    postServiceReturn,
    apple: findConnection("apple"),
    google: findConnection("google"),
    fitbit: findConnection("fitbit"),
    garmin: findConnection("garmin"),
  };

  function useMethods() {
    return {
      findConnection: (ident: I) => {
        const connection = api.data.find(byIdent(ident));
        if (!connection) {
          console.error(`Connection with ident ${ident} not found`);
        }
        return (connection as unknown) as Connection;
      },
      byIdent: (ident: I) => (c: Connection) => ident === c.service_ident,
      postServiceReturn: async (service_ident: string, return_to: string) => {
        const api = geApi(`data/connections`);
        return await api.post({
          service_ident,
          return_to,
        });
      },
    };
  }
};

export const useGeSteps = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const api = useGeApi<Steps>(
    "steps/0",
    {
      days: 365,
      tz: encodeURIComponent(timezone),
    },
    "get",
    false
  );

  return {
    ...api,
    steps: api.data,
    fetch: api.refetch,
    error: api.error,
  };
};

export const useGeManualSteps = () => {
  const api = useGeApi<Steps>("steps/manual/0", {}, "get", false);

  return {
    ...api,
    steps: api.data,
    fetch: api.refetch,
    error: api.error,
  };
};

export const useGeBrandGoalTypes = () => {
  const endpoint = useGeEndpoint<BrandGoalType>("brand_goal_types");

  const exclusiveTypes = [
    GOAL_TYPE_IDENTIFIERS.STREAK,
    GOAL_TYPE_IDENTIFIERS.QUEST,
    GOAL_TYPE_IDENTIFIERS.MILESTONE,
  ];

  const filterExclusive = (goalType: BrandGoalType) =>
    !exclusiveTypes.some((excluded) => goalType.goal_type_ident === excluded);

  const genericGoalTypes = endpoint.data
    .filter(filterExclusive)
    .map((goalType) => goalType.goal_type_ident)
    .join("-");

  return {
    ...endpoint,
    goalTypes: endpoint.data,
    genericGoalTypes,
  };
};
